<template>
  <div class="flex flex-col lg:flex-row gap-x-12">
    <div class="w-full lg:w-2/3">
      <div class="bg-white p-6 pt-8 sm:p-8 sm:pt-12 md:p-12 md:pt-16">
        <div class="text-content">
          <h1 class="flex flex-col">
            <span v-text="item.title" />
            <span
              class="bg-red-500 h-px inline-block w-16 mt-3"
              style="height: 2px"
            />
          </h1>
          <p>
            <picture>
              <source
                :srcset="
                  `/img/services/service${item.id}.jpg.webp 1x, /img/services/service${item.id}@2x.jpg.webp 2x`
                "
                type="image/webp"
              />
              <source
                :srcset="
                  `/img/services/service${item.id}.jpg 1x, /img/services/service${item.id}@2x.jpg 2x`
                "
                type="image/jpeg"
              />
              <img
                :src="`/img/services/service${item.id}.jpg`"
                :alt="item.title"
                loading="lazy"
                class="w-full"
              />
            </picture>
          </p>
          <div v-html="item.description" />
        </div>
      </div>

      <div class="bg-white p-6 sm:p-8 md:p-12 bg-opacity-90  mb-8">
        <h2
          class="text-center font-headings text-xl  mb-4 text-black max-w-lg mx-auto"
          v-text="item.cta_text"
        ></h2>
        <router-link
          to="/step-1"
          class="bg-red-500 p-4 text-white w-full flex items-center justify-center text-center font-bold rounded transition-colors active:bg-red-600"
        >
          <span v-text="$t('button.get_started')" />
          <inline-svg
            src="/img/icon-right.svg"
            class="fill-current ml-3 animate-arrow"
          />
        </router-link>
      </div>
    </div>
    <div class="w-full lg:w-1/3 lg:pl-5">
      <services-list isSidebar />
    </div>
  </div>
</template>

<script>
import ServicesList from "../components/ServicesList.vue";
export default {
  name: "WhyAreWeUnique",
  components: {
    ServicesList,
  },
  data() {
    return {
      item: "",
    };
  },
  mounted() {
    this.getService();
  },
  methods: {
    async getService() {
      const currentService = this.services.filter(
        (item) => item.path === this.$route.params.id
      )[0];
      currentService ? (this.item = currentService) : this.$router.push("/");
    },
  },
  computed: {
    services() {
      return this.$store.state.services;
    },
  },
  watch: {
    $route() {
      this.getService();
    },
  },
  metaInfo() {
    return { title: this.item.title };
  },
};
</script>
